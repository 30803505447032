import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditCategory = ({ categoryToEdit, setActiveComponent }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [category, setCategory] = useState({
    id: '',
    categoryName: '',
    categoryDescription: '',
    createdDate: '',
    modifiedDate: '',
    isActive: true,
    imageUrl: ''
  });

  useEffect(() => {
    // Simulate progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 10; // Increase progress by 10%
      });
    }, 500); // Update progress every 500ms
  }, []);

  useEffect(() => {
    if (categoryToEdit) {
      setCategory(categoryToEdit);
    }
  }, [categoryToEdit]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCategory(prevCategory => ({
      ...prevCategory,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.put(`/api/category/update/${category.id}`, {
        categoryName: category.categoryName,
        categoryDescription: category.categoryDescription,
        createdDate: category.createdDate,
        modifiedDate: new Date().toISOString(), // Update modifiedDate to current time
        isActive: category.isActive,
        imageUrl: category.imageUrl
      });
  
      if (response.status === 200 || response.status === 204) {
        toast.success('Category successfully updated!', {
          position: "top-right"
        });

        // Redirect back to category list or manage components after a delay
        setTimeout(() => {
          setActiveComponent('category');
        }, 2000); // Delay of 2000ms (2 seconds) before redirecting
      } else {
        toast.error('Failed to update category. Please try again.');
      }
    } catch (error) {
      console.error('Error updating category:', error);
      toast.error('Failed to update category. Please try again.');
    }
  };

  const handleCancel = () => {
    resetForm();

    // Redirect back to category list or manage components
    setActiveComponent('category');
  };

  const resetForm = () => {
    setCategory({
      id: '',
      categoryName: '',
      categoryDescription: '',
      createdDate: '',
      modifiedDate: '',
      isActive: true,
      imageUrl: ''
    });
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Loading...
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {progress}%
                  </span>
                </div>
              </div>
              <progress className="progress progress-primary w-full" value={progress} max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="container mx-auto p-4">
      <Header />
      <div className="bg-white mt-5 p-4 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-gray-700 font-bold">Edit Category</h4>
            <h6 className="text-sm text-gray-500">Update category details</h6>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <div className="form-control mb-4">
              <label className="label" htmlFor="categoryName">
                <span className="label-text text-gray-700">Category Name</span>
              </label>
              <input
                type="text"
                name="categoryName"
                id="categoryName"
                value={category.categoryName}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Category Name"
                required // <-- Required attribute
              />
            </div>
              <div className="form-control mb-4">
              <label className="label" htmlFor="categoryDescription">
                <span className="label-text text-gray-700">Description</span>
              </label>
              <textarea
                name="categoryDescription"
                id="categoryDescription"
                value={category.categoryDescription}
                onChange={handleChange}
                className="textarea textarea-bordered w-full bg-white text-gray-700"
                placeholder="Category Description"
                required // <-- Required attribute
              />
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="isActive">
                <span className="label-text text-gray-700">Status</span>
              </label>
              <select
                name="isActive"
                id="isActive"
                value={category.isActive ? 'Active' : 'Inactive'}
                onChange={(e) => setCategory({ ...category, isActive: e.target.value === 'Active' })}
                className="select select-bordered w-1/4 bg-white text-gray-700"
                required // <-- Required attribute
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="imageUrl">
                <span className="label-text text-gray-700">Image URL</span>
              </label>
              <input
                type="text"
                name="imageUrl"
                id="imageUrl"
                value={category.imageUrl}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Image URL"
                required // <-- Required attribute
              />
            </div>
          </div>
          {category.imageUrl && (
              <div className="col-span-1">
                <div className="form-control mb-4">
                  <label className="label">
                    <span className="label-text text-gray-700">Image Preview</span>
                  </label>
                  <img
                    src={category.imageUrl}
                    alt="Category Preview"
                    className="w-full h-auto border rounded"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="btn btn-primary mr-5 text-white"
            >
              Update Category
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="btn btn-secondary text-white"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};  

export default EditCategory;
