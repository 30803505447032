import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { FaPlus } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const Brand = ({ setActiveComponent, setBrandToEdit }) => {
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page
  const [deleteBrand, setDeleteBrand] = useState(null); // State to store the brand to be deleted
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    // Simulate progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 5; // Increase progress by 10%
      });
    }, 5000); // Update progress every 5 seconds
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get('/api/brand/getAll');
        console.log('API Response:', response.data); // Log the response data
        setBrands(response.data);
      } catch (error) {
        console.error('Error fetching brands:', error);
        setError(`Failed to fetch brands: ${error.message}`); // Provide detailed error message
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBrands = brands.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allBrandIds = brands.map(brand => brand.id);
      setSelectedBrands(allBrandIds);
    } else {
      setSelectedBrands([]);
    }
  };

  const handleSelectBrand = (id) => {
    setSelectedBrands(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(brandId => brandId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when items per page change
  };

  const totalPages = Math.ceil(brands.length / itemsPerPage);

  const openModal = (brand) => {
    setDeleteBrand(brand);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setDeleteBrand(null);
    setIsModalOpen(false);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`/api/brand/delete/${deleteBrand.id}`);
      setBrands(prevBrands => prevBrands.filter(b => b.id !== deleteBrand.id));
      closeModal();
    } catch (error) {
      console.error('Error deleting brand:', error);
      // Handle error, show toast message, etc.
    }
  };

  const handleEditBrand = (brand) => {
    setBrandToEdit(brand);
    setActiveComponent('EditBrand');
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Loading...
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {progress}%
                  </span>
                </div>
              </div>
              <progress className="progress progress-primary w-full" value={progress} max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="text-red-500 text-center">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 text-black font-dmsan">
      <Header />
      <div className="container bg-white mt-5 pb-4 pl-4 pr-4 pt-1 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-gray-700 font-bold">Brand List</h4>
            <h6 className="text-sm text-gray-500">Manage your brands</h6>
          </div>
          <button
            onClick={() => setActiveComponent('AddBrand')}
            className="btn flex items-center bg-[#3C91E6] text-white border-none hover:text-[#3C91E6] hover:bg-gray-100"
          >
            <FaPlus className="w-4 h-4 mr-2" />
            Add New Brand
          </button>
        </div>

        <div className="overflow-x-auto text-black">
          <table className="table w-full">
            <thead>
              <tr className='text-black text-sm font-dmsan'>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedBrands.length === brands.length}
                    />
                  </label>
                </th>
                <th>Brand Name</th>
                <th>Country</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentBrands.map(brand => (
                <tr key={brand.id}>
                  <th>
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedBrands.includes(brand.id)}
                        onChange={() => handleSelectBrand(brand.id)}
                      />
                    </label>
                  </th>
                  <td>{brand.brandName}</td>
                  <td>{brand.country}</td>
                  <td>
                    <span className={`badge text-gray-50 ${brand.isActive ? 'badge-success' : 'badge-danger'}`}>
                      {brand.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td>
                    <div className="flex space-x-2">
                      <button className="btn btn-sm btn-primary bg-[#3C91E6] hover:bg-white hover:text-[#3C91E6] px-6 text-white" onClick={() => handleEditBrand(brand)}>Edit</button>
                      <button className="btn btn-sm btn-secondary text-white hover:bg-white hover:text-[#ff00b8]" onClick={() => openModal(brand)}>Delete</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <label htmlFor="itemsPerPage">Show</label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="form-select bg-white border p-2 rounded"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>

          <div className="flex items-center space-x-2 bg-white p-2 rounded">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-3 bg-white text-gray-400 border border-gray-300 rounded hover:bg-gray-50"
            >
              <FaAngleLeft className="w-4 h-5 mr-2" />
            </button>
            <span className="px-4 py-2 text-black">Page {currentPage} of {totalPages}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-3 bg-white text-gray-400 border border-gray-300 rounded hover:bg-gray-50"
            >
              <FaAngleRight className="w-4 h-5 mr-2" />
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box bg-white">
            <h3 className="font-bold text-lg">Confirm Deletion</h3>
            <p className="py-4">Are you sure you want to delete {deleteBrand.brandName}?</p>
            <div className="modal-action">
              <button className="btn btn-error" onClick={confirmDelete}>Yes</button>
              <button className="btn" onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Brand;
