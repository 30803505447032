import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header';
import { FaAngleLeft } from "react-icons/fa6";

const AddBrand = ({ setActiveComponent }) => {
  const [brandName, setBrandName] = useState('');
  const [country, setCountry] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/brand/insert', {
        brandName,
        country,
        isActive,
      });
      setSuccess('Brand added successfully!');
      setBrandName('');
      setCountry('');
      setIsActive(true);
    } catch (error) {
      console.error('Error adding brand:', error);
      setError(`Failed to add brand: ${error.message}`);
    }
  };

  return (
    <div className="container mx-auto p-4 text-black font-dmsan">
      <Header />
      <div className="container bg-white mt-5 pb-4 pl-4 pr-4 pt-1 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-gray-700 font-bold">Add New Brand</h4>
            <h6 className="text-sm text-gray-500">Create a new brand entry</h6>
          </div>
          <button
            onClick={() => setActiveComponent('Brand')}
            className="btn flex items-center bg-[#3C91E6] text-white border-none hover:text-[#3C91E6] hover:bg-gray-100"
          >
            <FaAngleLeft className="w-4 h-4 mr-2" />
            Back to Brand List
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="brandName" className="block text-sm font-medium text-gray-700">Brand Name</label>
            <input
              type="text"
              id="brandName"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              className="form-input mt-1 block w-full border p-2 rounded bg-white"
              required
            />
          </div>

          <div>
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
            <input
              type="text"
              id="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="form-input mt-1 block w-full border p-2 rounded bg-white"
              required
            />
          </div>

          <div className="flex items-center">
          <label htmlFor="isActive" className="mr-2 text-sm font-medium text-gray-700">Active</label>
            <input
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="checkbox checkbox-primary"
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn bg-[#3C91E6] text-white border-none hover:text-[#3C91E6] hover:bg-gray-100"
            >
              Add Brand
            </button>
          </div>

          {error && <div className="text-red-500">{error}</div>}
          {success && <div className="text-green-500">{success}</div>}
        </form>
      </div>
    </div>
  );
};

export default AddBrand;
