import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { FaPlus } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const Product = ({ setActiveComponent, setProductToEdit }) => {
  const [progress, setProgress] = useState(0);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page
  const [deleteProduct, setDeleteProduct] = useState(null); // State to store the product to be deleted
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  
  useEffect(() => {
    // Simulate progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 10; // Increase progress by 10%
      });
    }, 500); // Update progress every 500ms
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get('/api/product/getAll');
        if (response.data.length === 0) {
          setError('Products are empty or products are not found');
        } else {
          setProducts(response.data);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to fetch products');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allProductIds = products.map(product => product.id);
      setSelectedProducts(allProductIds);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleSelectProduct = (id) => {
    setSelectedProducts(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(productId => productId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when items per page change
  };

  const totalPages = Math.ceil(products.length / itemsPerPage);

  const openModal = (product) => {
    setDeleteProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setDeleteProduct(null);
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    // Add your delete logic here
    console.log(`Deleting product: ${deleteProduct.productName}`);
    closeModal();
  };

  const handleEditProduct = (product) => {
    setProductToEdit(product);
    setActiveComponent('EditProduct');
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Loading...
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {progress}%
                  </span>
                </div>
              </div>
              <progress className="progress progress-primary w-full" value={progress} max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="text-red-500 text-center">{error}</div>
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="text-center text-gray-500">No products available.</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 text-black font-dmsan">
      <Header />
      <div className="container bg-white mt-5 pb-4 pl-4 pr-4 pt-1 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-black font-bold">Product List</h4>
            <h6 className="text-sm text-gray-500">Manage your products</h6>
          </div>
          <button
            onClick={() => setActiveComponent('addProduct')}
            className="btn flex items-center bg-[#3C91E6] text-white border-none hover:text-[#3C91E6] hover:bg-gray-100"
          >
            <FaPlus className="w-4 h-4 mr-2" />
            Add New Product
          </button>
        </div>

        <div className="overflow-x-auto text-black ">
          <table className="table w-full">
            <thead>
              <tr className='text-black text-sm font-dmsan'>
                <th className="bg-white">
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedProducts.length === products.length}
                    />
                  </label>
                </th>
                <th>Product</th>
                <th>SKU</th>
                <th>Category</th>
                <th>Brand</th>
                <th>Price</th>
                <th>Stock</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentProducts.map(product => (
                <tr key={product.id}>
                  <th className='bg-white'>
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedProducts.includes(product.id)}
                        onChange={() => handleSelectProduct(product.id)}
                      />
                    </label>
                  </th>
                  <td>
                    <div className="flex items-center space-x-3 text-black font-dmsan">
                      <div className="avatar">
                        <div className="mask mask-squircle w-12 h-12">
                          <img src={product.imageUrl} alt="Product Image" />
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">{product.productName}</div>
                        <div className="text-sm opacity-70">{product.productDescription}</div>
                      </div>
                    </div>
                  </td>
                  <td>{product.sku}</td>
                  <td>{product.categoryId}</td>
                  <td>{product.brand}</td>
                  <td>₱{product.price.toFixed(2)}</td>
                  <td>{product.quantity}</td>
                  <td>
                    <span className={`badge text-gray-50 ${product.isActive ? 'badge-success' : 'badge-danger'}`}>
                      {product.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td>
                    <div className="flex space-x-2">
                      <button className="btn btn-sm btn-primary bg-[#3C91E6] hover:bg-white hover:text-[#3C91E6] px-5 text-white" onClick={() => handleEditProduct(product)}>Edit</button>
                      <button className="btn btn-sm btn-secondary text-white hover:bg-white hover:text-[#ff00b8]" onClick={() => openModal(product)}>Delete</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <label htmlFor="itemsPerPage">Show</label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="form-select bg-white border p-2 rounded"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>

          <div className="flex items-center space-x-2 bg-white p-2 rounded">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-3 bg-white text-gray-400 border border-gray-300 rounded hover:bg-gray-50"
            >
              <FaAngleLeft className="w-4 h-5 mr-2" />
            </button>
            <span className="px-4 py-2 text-black">Page {currentPage} of {totalPages}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-3 bg-white text-gray-400 border border-gray-300 rounded hover:bg-gray-50"
            >
              <FaAngleRight className="w-4 h-5 mr-2" />
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box bg-white">
            <h3 className="font-bold text-lg">Confirm Deletion</h3>
            <p className="py-4">Are you sure you want to delete {deleteProduct.productName}?</p>
            <div className="modal-action">
              <button className="btn btn-error" onClick={confirmDelete}>Yes</button>
              <button className="btn" onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
