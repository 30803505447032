import React, { useState } from 'react';
import Dashboard from './components/Dashboard';
import Product from './components/Product';
import Sidebar from './components/Sidebar';
import AddProduct from './components/addProduct';
import EditProduct from './components/EditProduct';
import ProductsTable from './components/ProductsTable'; // Add this import
import Category from './components/Catergory';
import EditCategory from './components/EditCategory';
import AddCategory from './components/AddCategory';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Header from './components/Header';
import Brand from './components/Brand';
import ForgotPassword from './components/ForgotPassword';
import AddBrand from './components/AddBrand';

function App() {
  const [activeComponent, setActiveComponent] = useState('signin');
  const [productToEdit, setProductToEdit] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'signin':
        return <SignIn setActiveComponent={setActiveComponent} />;
      case 'signup':
        return <SignUp setActiveComponent={setActiveComponent} />;
      case 'forgotpassword':
        return <ForgotPassword setActiveComponent={setActiveComponent} />;
      case 'dashboard':
        return <Dashboard />;
      case 'product':
        return <Product 
                setActiveComponent={setActiveComponent} 
                setProductToEdit={setProductToEdit} 
                />;
      case 'addProduct':
        return <AddProduct 
                setActiveComponent={setActiveComponent} 
                productToEdit={productToEdit} 
                />;
      case 'EditProduct':
        return <EditProduct 
                setActiveComponent={setActiveComponent} 
                productToEdit={productToEdit} 
                />;
      case 'productTable':
        return <ProductsTable 
                setActiveComponent={setActiveComponent} 
                />;
      case 'category':
        return <Category 
                setActiveComponent={setActiveComponent} 
                setCategoryToEdit={setCategoryToEdit} 
                />;
      case 'AddCategory':
        return <AddCategory
                setActiveComponent={setActiveComponent} 
                categoryToEdit={categoryToEdit} 
                />;
      case 'EditCategory':
        return <EditCategory
                setActiveComponent={setActiveComponent} 
                categoryToEdit={categoryToEdit} 
                />;
      case 'Header':
        return <Header
                setActiveComponent={setActiveComponent} 
                />;
      case 'Brand':
        return <Brand
                setActiveComponent={setActiveComponent} 
                />;
      case 'AddBrand':
        return <AddBrand
                setActiveComponent={setActiveComponent} 
                />;// Pass prop here
      // Add other cases for additional components
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="flex w-full h-screen bg-[#f2f5f8]">
      {activeComponent === 'signin' || activeComponent === 'signup' || activeComponent === 'forgotpassword' ? (
        <>
          <div className="w-full flex items-center justify-center lg:w-1/2">
            {renderComponent()}
          </div>
          <div className="hidden relative w-1/2 h-full lg:flex items-center justify-center bg-gray-200">
            <div className="w-60 h-60 rounded-full bg-gradient-to-tr from-blue-400 to-blue-600 animate-bounce" />
            <div className="w-full h-1/2 absolute bottom-0 bg-white/10 backdrop-blur-lg" />
          </div>
        </>
      ) : (
        <>
          <div className="w-64 h-screen overflow-hidden hover:overflow-y-auto bg-[#f2f5f8]">
            <Sidebar setActiveComponent={setActiveComponent} />
          </div>
          <div className="flex-1 h-screen overflow-y-auto p-6 bg-[#f2f5f8]">
            {renderComponent()}
          </div>
        </>
      )}
    </div>
  );
}

export default App;
