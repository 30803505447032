import React from 'react';

function StatCard({ title, amount, icon: Icon, bgColor, bgIconColor }) {
  return (
    <div className={`p-4 rounded shadow transition-all duration-300 ease-in-out ${bgColor}`}>
      <div className="flex items-center">
        <div className={`p-3 rounded-full ${bgIconColor} transition-transform duration-300 ease-in-out hover:scale-125 opacity-100`}>
          <Icon className="w-6 h-6 text-white" />
        </div>
        <div className="ml-4">
          <p className="text-xl font-bold text-black">{amount}</p>
          <h4 className="text-base font-normal text-gray-500">{title}</h4>
        </div>
      </div>
    </div>
  );
}

export default StatCard;
