import * as React from 'react';

const ForgotPassword = ({ setActiveComponent, hideLowerPart }) => {
    return (
        <div className='w-11/12 max-w-[700px] px-10 py-20 rounded-3xl max-h-full bg-white border-2 border-gray-100'>
            <h1 className='text-5xl font-semibold text-black'>Forgot Password</h1>
            <p className='font-medium text-lg text-gray-500 mt-4'>Please enter your email to reset your password.</p>
            <div className='mt-8'>
                <div className='flex flex-col'>
                    <label className='text-lg font-medium text-black'>Email</label>
                    <input 
                        className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent text-black'
                        placeholder="Enter your email"
                    />
                </div>
                {!hideLowerPart && (
                    <div className='mt-8 flex flex-col gap-y-4'>
                        <button 
                            className='active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out transform py-4 bg-[#3C91E6] rounded-xl text-white font-bold text-lg'
                            onClick={() => setActiveComponent('signin')}
                        >
                            Reset Password
                        </button>
                        <button 
                            className='flex bg-gray-50 items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out transform py-4 rounded-xl text-gray-700 font-semibold text-lg border-2 border-gray-100'
                            onClick={() => setActiveComponent('signin')}
                        >
                            Back to Sign In
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;
