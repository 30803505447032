import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddCategory = ({ setActiveComponent }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [category, setCategory] = useState({
    name: '',
    description: '',
    status: 'Active',
    imageUrl: ''
  });

  useEffect(() => {
    // Simulate progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 10; // Increase progress by 10%
      });
    }, 500); // Update progress every 500ms
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory(prevCategory => ({
      ...prevCategory,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    const requiredFields = ['name', 'description', 'imageUrl'];
    const isValid = requiredFields.every(field => category[field]);

    if (!isValid) {
      toast.error('Please fill out all required fields.');
      return;
    }

    try {
      const response = await axios.post('/api/category/insert', {
        categoryName: category.name,
        categoryDescription: category.description,
        isActive: category.status === 'Active',
        imageUrl: category.imageUrl,
        createdDate: new Date().toISOString(),
        modifiedDate: new Date().toISOString()
      });

      if (response.status === 200 || response.status === 201) {
        // Delay showing success toast and redirecting
        setTimeout(() => {
          toast.success('Category successfully added!', {
            position: "top-right"
          });

          // Optional: Reset form fields after successful submission
          setCategory({
            name: '',
            description: '',
            status: 'Active',
            imageUrl: ''
          });

          // Redirect to category list or manage components after delay
          setTimeout(() => {
            setActiveComponent('category');
          }, 2000); // Delay of 2000ms (2 seconds) before redirecting
        }, 1000); // Delay of 1000ms (1 second) before showing success toast
      } else {
        toast.error('Failed to add category. Please try again.');
      }
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Failed to add category. Please try again.');
    }
  };
  if (loading) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Loading...
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {progress}%
                  </span>
                </div>
              </div>
              <progress className="progress progress-primary w-full" value={progress} max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const handleCancel = () => {
    setCategory({
      name: '',
      description: '',
      status: 'Active',
      imageUrl: ''
    });

    // Redirect back to category list or manage components
    setActiveComponent('category');
  };

  return (
    <div className="container mx-auto p-4 text-black font-dmsan">
      <Header />
      <div className="bg-white mt-5 p-4 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-gray-700 font-bold">Add New Category</h4>
            <h6 className="text-sm text-gray-500">Enter category details</h6>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <div className="form-control mb-4">
                <label className="label" htmlFor="name">
                  <span className="label-text text-gray-700">Category Name</span>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={category.name}
                  onChange={handleChange}
                  className="input input-bordered w-full bg-white text-gray-700"
                  placeholder="Category Name"
                  required // <-- Required attribute
                />
              </div>
              <div className="form-control mb-4">
                <label className="label" htmlFor="description">
                  <span className="label-text text-gray-700">Description</span>
                </label>
                <textarea
                  name="description"
                  id="description"
                  value={category.description}
                  onChange={handleChange}
                  className="textarea textarea-bordered w-full bg-white"
                  placeholder="Category Description"
                  required // <-- Required attribute
                />
              </div>
              <div className="form-control mb-4">
                <label className="label" htmlFor="status">
                  <span className="label-text text-gray-700">Status</span>
                </label>
                <select
                  name="status"
                  id="status"
                  value={category.status}
                  onChange={handleChange}
                  className="select select-bordered w-1/4 bg-white"
                  required // <-- Required attribute
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <div className="form-control mb-4">
                <label className="label" htmlFor="imageUrl">
                  <span className="label-text text-gray-700">Image URL</span>
                </label>
                <input
                  type="text"
                  name="imageUrl"
                  id="imageUrl"
                  value={category.imageUrl}
                  onChange={handleChange}
                  className="input input-bordered w-full bg-white text-gray-700"
                  placeholder="Image URL"
                  required // <-- Required attribute
                />
              </div>
            </div>
            {category.imageUrl && (
                <div className="col-span-1">
                  <div className="form-control mb-4">
                    <label className="label">
                      <span className="label-text text-gray-700">Image Preview</span>
                    </label>
                    <img
                      src={category.imageUrl}
                      alt="Category Preview"
                      className="w-full h-auto border rounded"
                    />
                  </div>
                </div>
              )}
          </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="btn btn-primary bg-[#3C91E6] hover:bg-white hover:text-[#3C91E6] mr-5 text-white"
              >
                Add Category
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-secondary text-white hover:bg-white hover:text-[#ff00b8]"
              >
                Cancel
              </button>
            </div>
        </form>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default AddCategory;
