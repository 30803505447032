import React, { useState } from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

const expiredProductsData = [
  { id: 1, code: 'IT0001', name: 'Orange', brand: 'N/D', category: 'Fruits', expiryDate: '12-12-2022', image: 'https://cdn.wallpapersafari.com/29/42/DIOdJZ.jpg' },
  { id: 2, code: 'IT0002', name: 'Pineapple', brand: 'N/D', category: 'Fruits', expiryDate: '25-11-2022', image: 'https://i.pinimg.com/originals/d7/fb/70/d7fb70cc018e6e7a17f0d3986cb4390c.jpg' },
  { id: 3, code: 'IT0003', name: 'Strawberry', brand: 'N/D', category: 'Fruits', expiryDate: '19-11-2022', image: 'https://d3d71ba2asa5oz.cloudfront.net/12001418/images/strawberryalbion.jpg' },
  { id: 4, code: 'IT0004', name: 'Avocat', brand: 'N/D', category: 'Fruits', expiryDate: '20-11-2022', image: 'https://img.passeportsante.net/1200x675/2021-05-03/i101969-avocat-nu.jpg' },
];

function ExpiredProductsTable() {
  const [products, setProducts] = useState(expiredProductsData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedProducts = [...products].sort((a, b) => {
      const valueA = key === 'expiryDate' ? new Date(a[key]) : a[key].toLowerCase();
      const valueB = key === 'expiryDate' ? new Date(b[key]) : b[key].toLowerCase();
      if (valueA < valueB) {
        return direction === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setProducts(sortedProducts);
    setSortConfig({ key, direction });
  };

  const getSortArrows = (key) => (
    <span className="flex flex-col ml-1">
      <MdKeyboardArrowUp className={`${sortConfig.key === key && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-400'}`} />
      <MdKeyboardArrowDown className={`${sortConfig.key === key && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-400'}`} />
    </span>
  );

  return (
    <div className="bg-white p-6 rounded border border-gray-100 shadow-sm h-full relative font-dmsan">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-black text-lg font-bold">Expired Products</h2>
      </div>
      <table className="w-full">
        <thead>
          <tr className="text-black bg-[#f2f5f8]">
            <th className="cursor-pointer py-3 px-6" onClick={() => handleSort('id')}>
              <div className="flex text-sm items-center">
                Sno {getSortArrows('id')}
              </div>
            </th>
            <th className="cursor-pointer py-3 px-6" onClick={() => handleSort('code')}>
              <div className="flex text-sm items-center">
                Product Code {getSortArrows('code')}
              </div>
            </th>
            <th className="cursor-pointer py-3 px-6" onClick={() => handleSort('name')}>
              <div className="flex text-sm items-center">
                Product Name {getSortArrows('name')}
              </div>
            </th>
            <th className="cursor-pointer py-3 px-6" onClick={() => handleSort('brand')}>
              <div className="flex text-sm items-center">
                Brand Name {getSortArrows('brand')}
              </div>
            </th>
            <th className="cursor-pointer py-3 px-6" onClick={() => handleSort('category')}>
              <div className="flex text-sm items-center">
                Category Name {getSortArrows('category')}
              </div>
            </th>
            <th className="cursor-pointer py-3 px-6" onClick={() => handleSort('expiryDate')}>
              <div className="flex text-sm items-center">
                Expiry Date {getSortArrows('expiryDate')}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id} className="hover:bg-gray-50 text-sm text-left border-b border-gray-200 text-black">
              <td className="py-3 px-6">{product.id}</td>
              <td className="py-3 px-6">{product.code}</td>
              <td className="py-3 px-6 flex items-center text-black">
                <img src={product.image} alt={product.name} className="w-10 h-10 object-cover mr-2 rounded" />
                {product.name}
              </td>
              <td className="py-3 px-6">{product.brand}</td>
              <td className="py-3 px-6">{product.category}</td>
              <td className="py-3 px-6">{product.expiryDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ExpiredProductsTable;
