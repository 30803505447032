import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import PropTypes from 'prop-types';

const productsData = [
  { id: 1, name: 'Palmolive', price: '₱65.00', sales: 10903, change: 2.2, image: 'https://cdn.shopify.com/s/files/1/0257/2425/6334/products/PalmoliveHealthy_SmoothShampoo180ml.jpg?v=1624883144' },
  { id: 2, name: 'Silka Lotion', price: '₱98.00', sales: 8667, change: 22, image: 'https://image.made-in-china.com/2f0j00oSrEfaAqbWcs/Silka-Skin-Whitening-Lotion-Papaya-500ml.jpg' },
  { id: 3, name: 'Colgate', price: '₱125.00', sales: 5364, change: 5.2, image: 'https://th.bing.com/th/id/OIP.7b4NLWtiD2Ua39Vww0yX4wHaEn?rs=1&pid=ImgDetMain' },
  { id: 4, name: 'Bioderm', price: '₱51.00', sales: 1234, change: 7.2, image: 'https://cdn.shopify.com/s/files/1/0257/1884/9583/products/57773.jpg?v=1672201339' },
  { id: 5, name: 'Payless Pancit Canton', price: '₱29.00', sales: 1234, change: 7.2, image: 'https://th.bing.com/th/id/OIP.EDcCVqZ1r1_aWbCp3Un7IAHaHa?rs=1&pid=ImgDetMain' },
];

function ProductsTable({ setActiveComponent }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMenuClick = (component) => {
    setActiveComponent(component);
    setDropdownOpen(false);
  };

    return (
      <div className="bg-white p-6 pb-9 rounded border border-gray-100 shadow-sm font-dmsan">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-black text-lg font-bold">Top Sales Product</h2>
          <button onClick={toggleDropdown} className="text-black p-2 rounded focus:outline-none" aria-label="Menu">
            <BsThreeDotsVertical size={20} />
          </button>
          {dropdownOpen && (
            <div className="absolute right-14 top-80 w-48 bg-white border rounded shadow-md">
              <ul>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black" onClick={() => handleMenuClick('product')}>Product List</li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-black" onClick={() => handleMenuClick('addProduct')}>Add Product</li>
              </ul>
            </div>
          )}
        </div>
        <ul>
          {productsData.map((product) => (
            <li key={product.id} className="flex items-center justify-between py-2 border-b last:border-b-0">
              <div className="flex items-center">
                <img src={product.image} alt={product.name} className="w-10 h-10 object-cover mr-4 rounded" />
                <div>
                  <div className="text-sm font-medium">{product.name}</div>
                  <div className="text-sm text-gray-500 text-left">{product.price}</div>
                </div>
              </div>
              <div className="text-right">
                <div className="text-sm font-medium text-[#3C91E6]">{product.sales} Products</div>
              </div>
              <div className="text-right">
                <div className={`text-sm bg-gray-200 px-1 rounded ${product.change > 0 ? 'text-green-500' : 'text-red-500'}`}>{product.change}%</div>
              </div>
            </li>
          ))}
        </ul>
        <button className="mt-4 w-full text-black shadow-md bg-white py-2 rounded-full hover:bg-[#3C91E6] hover:text-white focus:outline-none">Show More</button>
      </div>
    );
  }

ProductsTable.propTypes = {
  setActiveComponent: PropTypes.func.isRequired,
};

export default ProductsTable;
