import React, { useState } from 'react';
import logo1 from '../assets/img/Logo-L.png'; 
import { MdOutlineSpeed } from "react-icons/md";
import { BiPackage, BiReceipt, BiCart, BiCartAdd, BiTransferAlt, BiMapAlt, BiLayer, BiErrorAlt } from "react-icons/bi";
import { IoWalletOutline, IoReturnUpBack, IoDocumentOutline } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import { RiArrowRightSLine, RiArrowDownSLine } from "react-icons/ri";
import { FaRegCircle, FaCircle } from "react-icons/fa";

function Sidebar({ setActiveComponent }) {
  const [isProductMenuOpen, setProductMenuOpen] = useState(false);
  const [hoveringProductList, setHoveringProductList] = useState(null);
  const [isSalesMenuOpen, setSalesMenuOpen] = useState(false);
  const [activeComponent, setActive] = useState('dashboard');

  const toggleProductMenu = () => {
    setProductMenuOpen(!isProductMenuOpen);
  };

  const toggleSalesMenu = () => {
    setSalesMenuOpen(!isSalesMenuOpen);
  };

  const handleItemClick = (component) => {
    setActive(component);
    setActiveComponent(component);
  };

  const productLinks = [
    { name: 'Product List', component: 'product' },
    { name: 'Add Product', component: 'addProduct' },
    { name: 'Category List', component: 'category' },
    { name: 'Add Category', component: 'AddCategory' },
    { name: 'Brand List', component: 'Brand' },
    { name: 'Add Brand', component: 'AddBrand' },
    { name: 'Import Products', component: 'importProducts' },
    { name: 'Print Barcode', component: 'printBarcode' }
  ];

  return (
    <div className="relative h-full flex flex-col">
      <div className="fixed top-0 w-64 bg-white text-gray-500 p-4 z-10">
      <div className="flex items-center justify-center">
      <div className="logo flex items-center justify-center" onClick={() => handleItemClick('dashboard')}>
      <img src={logo1} className="w-4/5 max-w-xl p-4 bg-white rounded-lg shadow-2xl opacity-75 hover:opacity-100 cursor-pointer" alt="Logo1" />
      </div>
    </div>
      </div>
      <div className="flex-1 mt-20 bg-white overflow-y-auto scrollbar-thumb-gray-700 scrollbar-track-gray-200">
        <ul className="text-left text-gray-500 p-4 mt-auto">
          <li
            className={`rounded p-2 flex items-center mb-4 cursor-pointer  ${activeComponent === 'dashboard' ? 'bg-[#3C91E6] text-white' : 'hover:bg-[#3C91E6] hover:text-white'}`}
            onClick={() => handleItemClick('dashboard')}
          >
            <MdOutlineSpeed className="ml-2 mr-2 mb-1" size={20} />
            Dashboard
          </li>
          <li
            className={`rounded p-2 cursor-pointer flex items-center ${isProductMenuOpen ? 'bg-[#3C91E6] text-white' : 'hover:bg-[#3C91E6] hover:text-white'}`}
            onClick={toggleProductMenu}
          >
            <BiPackage className="ml-2 mr-2" size={20} />
            Product
            {isProductMenuOpen ? (
              <RiArrowDownSLine className="ml-auto transition-transform" size={20} />
            ) : (
              <RiArrowRightSLine className="ml-auto transition-transform" size={20} />
            )}
          </li>
          <ul
            className={`pl-2 mt-2 mb-2 rounded-lg text-sm text-gray-700 bg-gray-100 space-y-2 transition-all duration-1000 ${
              isProductMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
            }`}
          >
            {productLinks.map((item, index) => (
              <li key={index} onClick={() => handleItemClick(item.component)}>
                <div
                  className={`block ${hoveringProductList === index ? 'text-white' : ''} ${activeComponent === item.component ? 'bg-[#3C91E6] text-white' : ''} hover:text-[#73B5F8] rounded p-2 flex items-center cursor-pointer`}
                  onMouseEnter={() => setHoveringProductList(index)}
                  onMouseLeave={() => setHoveringProductList(null)}
                >
                  {hoveringProductList === index ? (
                    <FaCircle className="inline mr-2" size={12} style={{ backgroundColor: '#73B5F8', borderRadius: '50%', padding: '2px' }} />
                  ) : (
                    <FaRegCircle className="inline mr-2" size={12} />
                  )}
                  {item.name}
                </div>
              </li>
            ))}
          </ul>
          <li
            className={`rounded p-2 mb-4 cursor-pointer flex items-center ${isSalesMenuOpen ? 'bg-[#3C91E6] text-white' : 'hover:bg-[#3C91E6] hover:text-white'}`}
            onClick={toggleSalesMenu}
          >
            <BiCart className="ml-2 mr-2" size={20} />
            Sales
            {isSalesMenuOpen ? (
              <RiArrowDownSLine className="ml-auto transition-transform" size={20} />
            ) : (
              <RiArrowRightSLine className="ml-auto transition-transform" size={20} />
            )}
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'purchase' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('purchase')}>
            <BiReceipt className="ml-2 mr-2" size={20} />
            Purchase
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'expense' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('expense')}>
            <IoWalletOutline className="ml-2 mr-2" size={20} />
            Expense
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'quotation' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('quotation')}>
            <BiCartAdd className="ml-2 mr-2" size={20} />
            Quotation
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'transfer' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('transfer')}>
            <BiTransferAlt className="ml-2 mr-2" size={20} />
            Transfer
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'return' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('return')}>
            <IoReturnUpBack className="ml-2 mr-2" size={20} />
            Return
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'people' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('people')}>
            <FiUser className="ml-2 mr-2" size={20} />
            People
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'places' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('places')}>
            <BiMapAlt className="ml-2 mr-2" size={20} />
            Places
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'components' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('components')}>
            <BiLayer className="ml-2 mr-2" size={20} />
            Components
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'blankPage' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('blankPage')}>
            <IoDocumentOutline className="ml-2 mr-2" size={20} />
            Blank Page
          </li>
          <li className={`hover:bg-[#3C91E6] hover:text-white rounded p-2 cursor-pointer flex items-center mb-4 ${activeComponent === 'errorPages' ? 'bg-gray-700 text-white' : ''}`} onClick={() => handleItemClick('errorPages')}>
            <BiErrorAlt className="ml-2 mr-2" size={20} />
            Error Pages
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
