import React, { useState, useEffect } from 'react';
import Header from './Header';
import { FaRegCircleXmark } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddProduct = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  const generateSku = () => {
    return `SKU${Math.floor(100000 + Math.random() * 900000)}`;
  };

  const generateSerialNumber = () => {
    return `SN${Math.floor(100000 + Math.random() * 900000)}`;
  };

  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0]; // YYYY-MM-DD format
  };

  const [product, setProduct] = useState({
    id: '',
    categoryId: '',
    supplierId: '',
    userId: '',
    productName: '',
    category: '',
    subCategory: '',
    productDescription: '',
    brand: '',
    unit: '',
    minQuantity: 0,
    quantity: 0,
    sku: generateSku(),
    serialNumber: generateSerialNumber(),
    tax: '',
    discountType: 'Percentage',
    price: null, // Initialize as null to manage float parsing
    status: 'Closed',
    imageUrl: '',
    createdDate: getCurrentDate(),
    modifiedDate: getCurrentDate(),
    isActive: false
  });

  useEffect(() => {
    // Simulate progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 10; // Increase progress by 10%
      });
    }, 500); // Update progress every 500ms
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setProduct(prevProduct => ({
        ...prevProduct,
        [name]: checked
      }));
    } else if (name === 'minQuantity' || name === 'quantity') {
      // Ensure non-negative integers for minQuantity and quantity
      const intValue = parseInt(value);
      if (intValue < 0) {
        return;
      }
      setProduct(prevProduct => ({
        ...prevProduct,
        [name]: intValue
      }));
    } else if (name === 'price') {
      // Ensure non-negative float for price
      const floatValue = parseFloat(value);
      if (floatValue < 0 || isNaN(floatValue)) {
        return;
      }
      setProduct(prevProduct => ({
        ...prevProduct,
        [name]: floatValue
      }));
    } else {
      setProduct(prevProduct => ({
        ...prevProduct,
        [name]: value
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    const requiredFields = [
      'id', 'categoryId', 'supplierId', 'userId', 'productName', 'category', 
      'subCategory', 'productDescription', 'brand', 'unit', 'minQuantity', 
      'quantity', 'tax', 'discountType', 'price', 'isActive'
    ];
    const isValid = requiredFields.every(field => {
      if (field === 'price') {
        return typeof product[field] === 'number' && product[field] >= 0;
      }
      return product[field];
    });

    if (!isValid) {
      toast.error('Please fill out all required fields with valid inputs.');
      return;
    }

    // Automatically update modified date
    setProduct(prevProduct => ({
      ...prevProduct,
      modifiedDate: getCurrentDate()
    }));

    console.log('Product added:', product);
    // Add product to the product list logic goes here

    toast.success('Product successfully added!', {
      position: "top-right"
    });

    // Optional: Reset form fields after successful submission
    setProduct({
      id: '',
      categoryId: '',
      supplierId: '',
      userId: '',
      productName: '',
      category: '',
      subCategory: '',
      productDescription: '',
      brand: '',
      unit: '',
      minQuantity: 0,
      quantity: 0,
      sku: generateSku(),
      serialNumber: generateSerialNumber(),
      tax: '',
      discountType: 'Percentage',
      price: null, // Reset price to null
      status: 'Closed',
      imageUrl: '',
      createdDate: getCurrentDate(),
      modifiedDate: getCurrentDate(),
      isActive: false
    });
    document.getElementById('imageUrl').value = ''; // Reset file input field
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProduct({ ...product, imageUrl: file });
    }
  };

  const handleImageRemove = () => {
    setProduct({ ...product, imageUrl: '' });
    document.getElementById('imageUrl').value = ''; // Reset file input field
  };

  const handleCancel = () => {
    setProduct({
      id: '',
      categoryId: '',
      supplierId: '',
      userId: '',
      productName: '',
      category: '',
      subCategory: '',
      productDescription: '',
      brand: '',
      unit: '',
      minQuantity: 0,
      quantity: 0,
      sku: generateSku(),
      serialNumber: generateSerialNumber(),
      tax: '',
      discountType: 'Percentage',
      price: null, // Reset price to null
      status: 'Closed',
      imageUrl: '',
      createdDate: getCurrentDate(),
      modifiedDate: getCurrentDate(),
      isActive: false
    });
    document.getElementById('imageUrl').value = ''; // Reset file input field
  };
  
  if (loading) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Loading...
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {progress}%
                  </span>
                </div>
              </div>
              <progress className="progress progress-primary w-full" value={progress} max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 text-black font-dmsan">
      <Header />
      <div className="bg-white mt-5 p-4 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-gray-700 font-bold">Add New Product</h4>
            <h6 className="text-sm text-gray-500">Enter product details</h6>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 gap-4">
            {/* id */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="id">
                <span className="label-text text-gray-700">ID</span>
              </label>
              <input
                type="text"
                name="id"
                id="id"
                value={product.id}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="ID"
                required // <-- Required attribute
              />
            </div>

            {/* categoryId */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="categoryId">
                <span className="label-text text-gray-700">Category ID</span>
              </label>
              <input
                type="text"
                name="categoryId"
                id="categoryId"
                value={product.categoryId}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Category ID"
                required // <-- Required attribute
              />
            </div>

            {/* supplierId */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="supplierId">
                <span className="label-text text-gray-700">Supplier ID</span>
              </label>
              <input
                type="text"
                name="supplierId"
                id="supplierId"
                value={product.supplierId}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Supplier ID"
                required // <-- Required attribute
              />
            </div>

            {/* userId */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="userId">
                <span className="label-text text-gray-700">User ID</span>
              </label>
              <input
                type="text"
                name="userId"
                id="userId"
                value={product.userId}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="User ID"
                required // <-- Required attribute
              />
            </div>

            {/* productName */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="productName">
                <span className="label-text text-gray-700">Product Name</span>
              </label>
              <input
                type="text"
                name="productName"
                id="productName"
                value={product.productName}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Product Name"
                required // <-- Required attribute
              />
            </div>

            {/* productDescription */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="productDescription">
                <span className="label-text text-gray-700">Product Description</span>
              </label>
              <textarea
                name="productDescription"
                id="productDescription"
                value={product.productDescription}
                onChange={handleChange}
                className="textarea textarea-bordered w-full bg-white text-gray-700"
                placeholder="Product Description"
                required // <-- Required attribute
              ></textarea>
            </div>

            {/* brand */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="brand">
                <span className="label-text text-gray-700">Brand</span>
              </label>
              <input
                type="text"
                name="brand"
                id="brand"
                value={product.brand}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Brand"
                required // <-- Required attribute
              />
            </div>

            {/* unit */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="unit">
                <span className="label-text text-gray-700">Unit</span>
              </label>
              <input
                type="text"
                name="unit"
                id="unit"
                value={product.unit}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Unit"
                required // <-- Required attribute
              />
            </div>

            {/* minQuantity */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="minQuantity">
                <span className="label-text text-gray-700">Min Quantity</span>
              </label>
              <input
                type="number"
                name="minQuantity"
                id="minQuantity"
                value={product.minQuantity}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Min Quantity"
                required // <-- Required attribute
                min="0" // <-- Non-negative
              />
            </div>

            {/* quantity */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="quantity">
                <span className="label-text text-gray-700">Quantity</span>
              </label>
              <input
                type="number"
                name="quantity"
                id="quantity"
                value={product.quantity}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Quantity"
                required // <-- Required attribute
                min="0" // <-- Non-negative
              />
            </div>

            {/* tax */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="tax">
                <span className="label-text text-gray-700">Tax</span>
              </label>
              <input
                type="text"
                name="tax"
                id="tax"
                value={product.tax}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Tax"
                required // <-- Required attribute
              />
            </div>

            {/* discountType */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="discountType">
                <span className="label-text text-gray-700">Discount Type</span>
              </label>
              <select
                name="discountType"
                id="discountType"
                value={product.discountType}
                onChange={handleChange}
                className="select select-bordered w-full bg-white text-gray-700"
                required // <-- Required attribute
              >
                <option value="Percentage">Percentage</option>
                <option value="Flat">Flat</option>
              </select>
            </div>

            {/* price */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="price">
                <span className="label-text text-gray-700">Price</span>
              </label>
              <input
                type="number"
                name="price"
                id="price"
                value={product.price}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Price"
                required // <-- Required attribute
                min="0" // <-- Non-negative
                step="0.01" // <-- Allow decimal values
              />
            </div>

            {/* isActive */}
            <div className="form-control mb-4">
              <label className="label cursor-pointer" htmlFor="isActive">
                <span className="label-text text-gray-700">Active</span>
                <input
                  type="checkbox"
                  name="isActive"
                  id="isActive"
                  checked={product.isActive}
                  onChange={handleChange}
                  className="checkbox checkbox-primary"
                />
              </label>
            </div>

            {/* imageUrl */}
            <div className="form-control mb-4">
              <label className="label" htmlFor="imageUrl">
                <span className="label-text text-gray-700">Product Image</span>
              </label>
              <input
                type="file"
                name="imageUrl"
                id="imageUrl"
                accept="image/*"
                onChange={handleImageChange}
                className="file-input file-input-bordered w-full bg-white text-gray-700"
                required={!product.imageUrl} // <-- Required if no imageUrl
              />
              {product.imageUrl && (
                <div className="mt-2 flex justify-between items-center">
                  <span className="text-sm text-gray-500">{product.imageUrl.name || product.imageUrl}</span>
                  <button
                    type="button"
                    className="btn btn-outline btn-sm"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Add Product
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddProduct;
