import React from 'react';

function StatsCard({ title, amount, icon: Icon, bgColor }) {
  return (
    <div className={`p-4 rounded shadow transition-all duration-500 ease-in-out ${bgColor}`}>
      <div className="flex items-center">
        <div className="flex-1 text-left">
          <p className="text-2xl font-bold text-white">{amount}</p>
          <h4 className="text-sm font-normal text-white overflow-hidden whitespace-nowrap overflow-ellipsis">{title}</h4>
        </div>
        <div className="p-3 rounded-full text-white transition-transform duration-500 ease-in-out hover:scale-150">
          <Icon className="w-12 h-12" />
        </div>
      </div>
    </div>
  );
}

export default StatsCard;
