import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import 'daisyui/dist/full.css'; // Import DaisyUI CSS

const data = [
  { name: 'Jan', Sales: 50, Purchase: -45 },
  { name: 'Feb', Sales: 45, Purchase: -50 },
  { name: 'Mar', Sales: 60, Purchase: -60 },
  { name: 'Apr', Sales: 70, Purchase: -50 },
  { name: 'May', Sales: 50, Purchase: -45 },
  { name: 'Jun', Sales: 45, Purchase: -60 },
  { name: 'Jul', Sales: 60, Purchase: -50 },
  { name: 'Aug', Sales: 70, Purchase: -60 },
];

const views = ['Day', 'Month', 'Year'];

function CustomLabelTop({ x, y, value }) {
  return (
    <text x={x} y={y - 10} fill="#000000" textAnchor="start" className="text-sm">
      {value}
    </text>
  );
}

function CustomLabelBottom({ x, y, value }) {
  return (
    <text x={x} y={y + 20} fill="#000000" textAnchor="start" className="text-sm">
      {value}
    </text>
  );
}

function CustomAxisTick({ x, y, payload }) {
  return (
    <text x={x} y={y + 10} fill="#000000" textAnchor="middle" className="text-sm">
      {payload.value}
    </text>
  );
}

function CustomYAxisTick({ x, y, payload }) {
  return (
    <text x={x - 10} y={y + 4} fill="#000000" textAnchor="end" className="text-sm">
      {payload.value}
    </text>
  );
}

function CustomLegend({ payload }) {
  return (
    <div className="flex justify-around mt-4">
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className="flex items-center">
          <div
            style={{
              width: 12,
              height: 12,
              backgroundColor: entry.color,
              borderRadius: '50%',
              marginRight: 5,
            }}
          />
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  );
}

function Chart() {
  const [selectedView, setSelectedView] = useState('Year');

  return (
    <div className="container mx-auto bg-white p-6 rounded shadow-sm border border-gray-100 font-dmsan">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-black text-lg font-bold">Purchase & Sales</h2>
        <div className="flex bg-gray-100 p-1 rounded">
          {views.map((view, index) => (
            <button
            key={view}
            onClick={() => setSelectedView(view)}
            className={`btn btn-sm rounded mx-1 border-none ${selectedView === view ? 'btn-primary bg-[#3C91E6] text-white' : 'btn-outline text-black'}`}
            >
              {view}
            </button>
          ))}
        </div>
      </div>
      <div className="w-full overflow-x-auto text-sm text-black">
        <div className="min-w-[500px]"> {/* Adjust the min-width as needed */}
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              style={{ color: 'black'}}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tick={<CustomAxisTick />} />
              <YAxis domain={[-90, 90]} tick={<CustomYAxisTick />} />
              <Tooltip />
              <Legend content={<CustomLegend />} />
              <Bar dataKey="Sales" fill="#28a745" radius={[10, 10, 0, 0]}>
              <LabelList dataKey="Sales" position="top" content={<CustomLabelTop />} />
              </Bar>
              <Bar dataKey="Purchase" fill="#dc3545" radius={[10, 10, 0, 0]}>
              <LabelList dataKey="Purchase" position="bottom" content={<CustomLabelBottom />} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default Chart;
