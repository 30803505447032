import React, { useState, useEffect } from 'react';
import Header from './Header';
import { FaRegCircleXmark } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditProduct = ({ productToEdit, setActiveComponent }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [product, setProduct] = useState({
    name: '',
    category: '',
    subCategory: '',
    brand: '',
    unit: '',
    sku: '',
    minQty: 0,
    quantity: 0,
    description: '',
    tax: '',
    discountType: 'Percentage',
    price: '',
    status: 'Closed',
    imageUrl: ''
  });
  
  useEffect(() => {
    // Simulate progress
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 10; // Increase progress by 10%
      });
    }, 500); // Update progress every 500ms
  }, []);

  useEffect(() => {
    if (productToEdit) {
      setProduct(productToEdit);
    }
  }, [productToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if ((name === 'minQty' || name === 'quantity') && parseInt(value) < 0) {
      return;
    }
    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: name === 'minQty' || name === 'quantity' ? parseInt(value) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    const requiredFields = ['name', 'category', 'subCategory', 'brand', 'unit', 'sku', 'minQty', 'quantity', 'description', 'tax', 'price'];
    const isValid = requiredFields.every(field => product[field]);

    if (!isValid) {
      toast.error('Please fill out all required fields.');
      return;
    }

    // Logic for updating the product
    console.log('Product updated:', product);
    toast.success('Product successfully updated!', {
      position: "top-right"
    });

    // Optional: Reset form fields after successful submission
    setProduct({
      name: '',
      category: '',
      subCategory: '',
      brand: '',
      unit: '',
      sku: '',
      minQty: 0,
      quantity: 0,
      description: '',
      tax: '',
      discountType: 'Percentage',
      price: '',
      status: 'Closed',
      imageUrl: ''
    });
    document.getElementById('imageUrl').value = ''; // Reset file input field

    // Redirect back to product list
    setActiveComponent('productList');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProduct({ ...product, imageUrl: file });
    }
  };

  const handleImageRemove = () => {
    setProduct({ ...product, imageUrl: '' });
    document.getElementById('imageUrl').value = ''; // Reset file input field
  };

  const handleCancel = () => {
    setProduct({
      name: '',
      category: '',
      subCategory: '',
      brand: '',
      unit: '',
      sku: '',
      minQty: 0,
      quantity: 0,
      description: '',
      tax: '',
      discountType: 'Percentage',
      price: '',
      status: 'Closed',
      imageUrl: ''
    });
    document.getElementById('imageUrl').value = ''; // Reset file input field

    // Redirect back to product list
    setActiveComponent('product');
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Loading...
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {progress}%
                  </span>
                </div>
              </div>
              <progress className="progress progress-primary w-full" value={progress} max="100"></progress>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <Header />
      <div className="bg-white mt-5 p-4 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-gray-700 font-bold">Edit Product</h4>
            <h6 className="text-sm text-gray-500">Update product details</h6>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 gap-4">
            <div className="form-control mb-4">
              <label className="label" htmlFor="name">
                <span className="label-text text-gray-700">Product Name</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={product.name}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Product Name"
                required // <-- Required attribute
              />
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="category">
                <span className="label-text text-gray-700">Category</span>
              </label>
              <select
                name="category"
                id="category"
                value={product.category}
                onChange={handleChange}
                className="select select-bordered w-full bg-white text-gray-700"
                required // <-- Required attribute
              >
                <option value="">Choose Category</option>
                <option value="Category1">Category 1</option>
                <option value="Category2">Category 2</option>
              </select>
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="subCategory">
                <span className="label-text text-gray-700">Sub Category</span>
              </label>
              <select
                name="subCategory"
                id="subCategory"
                value={product.subCategory}
                onChange={handleChange}
                className="select select-bordered w-full bg-white text-gray-700"
                required // <-- Required attribute
              >
                <option value="">Choose Sub Category</option>
                <option value="SubCategory1">Sub Category 1</option>
                <option value="SubCategory2">Sub Category 2</option>
              </select>
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="brand">
                <span className="label-text text-gray-700">Brand</span>
              </label>
              <select
                name="brand"
                id="brand"
                value={product.brand}
                onChange={handleChange}
                className="select select-bordered w-full bg-white text-gray-700"
                required // <-- Required attribute
              >
                <option value="">Choose Brand</option>
                <option value="Brand1">Brand 1</option>
                <option value="Brand2">Brand 2</option>
              </select>
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="unit">
                <span className="label-text text-gray-700">Unit</span>
              </label>
              <select
                name="unit"
                id="unit"
                value={product.unit}
                onChange={handleChange}
                className="select select-bordered w-full bg-white text-gray-700"
                required // <-- Required attribute
              >
                <option value="">Choose Unit</option>
                <option value="Unit1">Unit 1</option>
                <option value="Unit2">Unit 2</option>
              </select>
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="sku">
                <span className="label-text text-gray-700">SKU</span>
              </label>
              <input
                type="text"
                name="sku"
                id="sku"
                value={product.sku}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="SKU"
                required // <-- Required attribute
              />
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="minQty">
                <span className="label-text text-gray-700">Minimum Qty</span>
              </label>
              <input
                type="number"
                name="minQty"
                id="minQty"
                value={product.minQty}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Minimum Qty"
                required // <-- Required attribute
              />
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="quantity">
                <span className="label-text text-gray-700">Quantity</span>
              </label>
              <input
                type="number"
                name="quantity"
                id="quantity"
                value={product.quantity}
                onChange={handleChange}
                className="input input-bordered w-full bg-white text-gray-700"
                placeholder="Quantity"
                required // <-- Required attribute
              />
            </div>
            <div className="form-control mb-4 col-span-4">
              <label className="label" htmlFor="description">
                <span className="label-text text-gray-700">Description</span>
              </label>
              <textarea
                name="description"
                id="description"
                value={product.description}
                onChange={handleChange}
                className="textarea textarea-bordered w-full bg-white"
                placeholder="Product Description"
                required // <-- Required attribute
              />
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="tax">
                <span className="label-text text-gray-700">Tax</span>
              </label>
              <select
                name="tax"
                id="tax"
                value={product.tax}
                onChange={handleChange}
                className="select select-bordered w-full bg-white"
                required // <-- Required attribute
              >
                <option value="">Choose Tax</option>
                <option value="Tax1">Tax 1</option>
                <option value="Tax2">Tax 2</option>
              </select>
            </div>
            <div className="form-control mb-4">
              <label className="label" htmlFor="price">
                <span className="label-text text-gray-700">Price</span>
              </label>
              <input
                type="number"
                name="price"
                id="price"
                value={product.price}
                onChange={handleChange}
                className="input input-bordered w-full bg-white"
                placeholder="Price"
                required // <-- Required attribute
              />
            </div>
            <div className="form-control mb-4 col-span-4">
              <label className="label" htmlFor="imageUrl">
                <span className="label-text text-gray-700">Product Image</span>
              </label>
              <input
                type="file"
                name="imageUrl"
                id="imageUrl"
                onChange={handleImageChange}
                className="bg-white file-input file-input-bordered w-full text-center item-center"
              />
              {product.imageUrl && (
                <div className="relative mt-4 bg-white p-2 rounded">
                  <button
                    type="button"
                    onClick={handleImageRemove}
                    className="absolute top-2 right-2 text-gray-700 hover:bg-gray-400 hover:rounded-lg hover:text-white hover:border-gray-800"
                  >
                    <FaRegCircleXmark className="w-6 h-6 " />
                  </button>
                  <img
                    src={typeof product.imageUrl === 'string' ? product.imageUrl : URL.createObjectURL(product.imageUrl)}
                    alt="Product Preview"
                    className="h-48 w-100 object-cover bg-white"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="btn btn-primary mr-5 text-white"
            >
              Update Product
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="btn btn-secondary text-white"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default EditProduct;
