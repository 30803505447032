import React from 'react';
import { IoDocumentTextOutline, IoDocumentOutline } from 'react-icons/io5';
import { FiUser, FiUserCheck } from "react-icons/fi";
import { TbArrowBarDown, TbArrowBarUp } from "react-icons/tb";
import { BsCash } from "react-icons/bs";
import { BiSolidShoppingBags } from "react-icons/bi";
import Header from './Header';
import StatsCard from './StatsCard';
import StatCard from './StatCard';
import Chart from './Chart';
import ProductsTable from './ProductsTable';
import ExpiredProductsTable from './ExpiredProductsTable';

function Dashboard() {
  return (
    <div className="flex h-screen bg-[#f2f5f8] text-black font-dmsan">
      <div className="flex-1 h-screen mt-5 text-center bg-[#f2f5f8]">
        <Header />
        <div className="grid grid-cols-4 gap-6 my-6">
          <StatCard title="Total Purchase Due" amount="₱307,144" icon={BiSolidShoppingBags} bgColor="bg-[#fff]" bgIconColor="bg-orange-400" />
          <StatCard title="Total Sales Due" amount="₱4,385" icon={BsCash} bgColor="bg-[#fff]" bgIconColor="bg-green-400" />
          <StatCard title="Total Sale Amount" amount="₱385,656.5" icon={TbArrowBarDown} bgColor="bg-[#fff]" bgIconColor="bg-blue-400" />
          <StatCard title="Total Sale Amount" amount="₱40,000" icon={TbArrowBarUp} bgColor="bg-[#fff]" bgIconColor="bg-red-400" />
        </div>
        <div className="grid grid-cols-4 gap-6 mb-6">
          <StatsCard title="Customers" amount="100" icon={FiUser} bgColor="bg-[#ff9f43]" />
          <StatsCard title="Suppliers" amount="100" icon={FiUserCheck} bgColor="bg-[#00cfe8]" />
          <StatsCard title="Purchase Invoice" amount="100" icon={IoDocumentTextOutline} bgColor="bg-[#1b2850]" />
          <StatsCard title="Sales Invoice" amount="105" icon={IoDocumentOutline} bgColor="bg-[#28c76f]" />
        </div>
        <div className="flex gap-6">
          <div className="flex-1">
            <Chart />
          </div>
          <div className="flex-1">
            <ProductsTable />
          </div>
        </div>
        <div className="flex-1 my-6">
          <ExpiredProductsTable />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
