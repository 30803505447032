import React, { useState } from 'react';
import { HiOutlineBell, HiOutlineChatAlt, HiOutlineSearch } from 'react-icons/hi';
import { FiUser, FiSettings } from 'react-icons/fi';
import { BiLogOutCircle } from "react-icons/bi";
import avatar1 from '../assets/img/profiles/avator1.jpg'; 

function Header({ setActiveComponent }) {
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const toggleMessagesDropdown = () => {
    setIsMessagesOpen(!isMessagesOpen);
    setIsNotificationsOpen(false);
    setIsProfileOpen(false);
  };

  const toggleNotificationsDropdown = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    setIsMessagesOpen(false);
    setIsProfileOpen(false);
  };

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
    setIsMessagesOpen(false);
    setIsNotificationsOpen(false);
  };

  const handleItemClick = (component) => {
    if (component === 'signin') {
      setActiveComponent('signin'); // Redirect to SignIn component
    } else {
      // Handle other menu item clicks if needed
      setActiveComponent(component); // Example: Profile, Settings, etc.
    }
  };

  return (
    <div className="bg-white font-dmsan -mt-5 h-16 px-4 flex items-center justify-between shadow-sm border-b border-gray-200 rounded">
      {/* Search Box */}
      <div className="relative flex items-center">
        <span className="absolute inset-y-0 left-0 pl-4 flex items-center">
          <HiOutlineSearch className="h-5 w-5 text-gray-500" />
        </span>
        <input
          type="text"
          placeholder="Search..."
          className="bg-white pl-11 px-4 py-2 border border-gray-300 rounded-3xl focus:outline-none focus:ring-2 focus:ring-blue-500 w-64 text-gray-700"
        />
      </div>

      {/* Messages, Notifications, and Profile */}
      <div className="flex items-center gap-4 ml-auto">
        {/* Messages */}
        <button 
          className="btn btn-ghost btn-circle" 
          onClick={toggleMessagesDropdown}
          style={{
            borderRadius: '50px',
            background: '#eeeeee',
            boxShadow: '4px 4px 2px #bebebe, -4px -4px 2px #ffffff'
          }}
          >
          <HiOutlineChatAlt fontSize={24} />
        </button>
        {isMessagesOpen && (
          <div className="absolute mt-6 top-12 right-0 z-10 w-64 bg-white border border-gray-300 rounded shadow-md p-4">
            <p>No new messages</p>
          </div>
        )}

        {/* Notifications */}
        <button 
          className="btn btn-ghost btn-circle"
          onClick={toggleNotificationsDropdown}
          style={{
            borderRadius: '50px',
            background: '#eeeeee',
            boxShadow: '4px 4px 2px #bebebe, -4px -4px 2px #ffffff'
          }}
          >
          <HiOutlineBell fontSize={24} />
        </button>
        {isNotificationsOpen && (
          <div className="absolute mt-6 top-12 right-0 z-10 w-64 bg-white border border-gray-300 rounded shadow-md p-4">
            <div className="flex items-center space-x-2">
              <svg
                className="w-6 h-6 text-blue-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
              </svg>
              <p className="text-sm">New notification</p>
            </div>
          </div>
        )}
        
        {/* Vertical line */}
        <div className="h-10 border-r border-gray-300 mx-2"></div>
        

        {/* Profile Avatar */}
        <div className="relative">
          <button 
            className="btn btn-ghost btn-circle" 
            onClick={toggleProfileDropdown}
            style={{
              background: '#eeeeee',
              boxShadow: '4px 4px 2px #bebebe, -4px -4px 2px #ffffff'
            }}
            >
            <div className="avatar">
              <div className="w-10 h-10 rounded-full">
                <img src={avatar1} alt="Profile" />
              </div>
            </div>
          </button>
          {isProfileOpen && (
            <div className="absolute mt-5 py-5 px-12 top-10 right-0.5 z-10 bg-white border border-gray-300 rounded shadow-md">
              <div className="profilename">
                <div className="profileset flex items-center">
                  <div className="profilesets">
                    <div className="text-black text-base font-medium">John Doe</div>
                    <div className="text-sm text-gray-500">Admin</div>
                  </div>
                </div>
                <hr className="w-20 my-2" />
                <div className="dropdown-item flex text-sm items-center py-2  text-black" onClick={() => handleItemClick('profile')}>
                  <FiUser className="mr-2" /> Profile
                </div>
                <div className="dropdown-item flex text-sm items-center py-2  text-black" onClick={() => handleItemClick('settings')}>
                  <FiSettings className="mr-2" /> Settings
                </div>
                <hr className="my-2" />
                <div className="dropdown-item flex text-sm items-center py-2  text-black" onClick={() => handleItemClick('signin')}>
                  <BiLogOutCircle className="mr-2 text-red-500 " />Logout
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
