import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { FaPlus, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const Category = ({ setActiveComponent, setCategoryToEdit }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [deleteCategory, setDeleteCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get('/api/category/getAll');
        console.log('API Response:', response.data);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(`Failed to fetch categories: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCategories = categories.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allCategoryIds = currentCategories.map((category) => category.id);
      setSelectedCategories(allCategoryIds);
    } else {
      setSelectedCategories([]);
    }
  };

  const handleSelectCategory = (id) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((categoryId) => categoryId !== id)
        : [...prevSelected, id]
    );
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(categories.length / itemsPerPage);

  const openModal = (category) => {
    setDeleteCategory(category);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setDeleteCategory(null);
    setIsModalOpen(false);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`/api/category/delete/${deleteCategory.id}`);
      setCategories((prevCategories) =>
        prevCategories.filter((cat) => cat.id !== deleteCategory.id)
      );
      closeModal();
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleEditCategory = (category) => {
    setCategoryToEdit(category);
    setActiveComponent('EditCategory');
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="flex justify-center items-center h-screen">
          <div className="w-full max-w-md">
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                    Loading...
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-blue-600">
                    {progress}%
                  </span>
                </div>
              </div>
              <progress
                className="progress progress-primary w-full"
                value={progress}
                max="100"
              ></progress>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4 text-black">
        <Header />
        <div className="text-red-500 text-center">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 text-black font-dmsan">
      <Header />
      <div className="container bg-white mt-5 pb-4 pl-4 pr-4 pt-1 shadow-sm border border-gray-100 rounded">
        <div className="flex justify-between items-center mt-4 mb-4">
          <div>
            <h4 className="text-xl text-gray-700 font-bold">Category List</h4>
            <h6 className="text-sm text-gray-500">Manage your categories</h6>
          </div>
          <button
            onClick={() => setActiveComponent('AddCategory')}
            className="btn flex items-center bg-[#3C91E6] text-white border-none hover:text-[#3C91E6] hover:bg-gray-100"
          >
            <FaPlus className="w-4 h-4 mr-2" />
            Add New Category
          </button>
        </div>

        <div className="overflow-x-auto text-black">
          <table className="table w-full">
            <thead>
              <tr className="text-black text-sm font-dmsan">
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedCategories.length === currentCategories.length}
                    />
                  </label>
                </th>
                <th>Category</th>
                <th>Description</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentCategories.map((category) => (
                <tr key={category.id}>
                  <th>
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectedCategories.includes(category.id)}
                        onChange={() => handleSelectCategory(category.id)}
                      />
                    </label>
                  </th>
                  <td>
                    <div className="flex items-center space-x-3 text-black">
                      <img
                        src={category.imageUrl}
                        alt={category.categoryName}
                        className="w-10 h-10 rounded-full"
                      />
                      <div className="font-semibold">
                        {category.categoryName}
                      </div>
                    </div>
                  </td>
                  <td>{category.categoryDescription}</td>
                  <td>
                    <span
                      className={`badge text-gray-50 ${
                        category.isActive
                          ? 'badge-success'
                          : 'badge-danger'
                      }`}
                    >
                      {category.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td>
                    <div className="flex space-x-2">
                      <button
                        className="btn btn-sm btn-primary bg-[#3C91E6] hover:bg-white hover:text-[#3C91E6] px-6 text-white"
                        onClick={() => handleEditCategory(category)}
                        disabled={selectedCategories.length !== 1 || !selectedCategories.includes(category.id)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-sm btn-secondary text-white hover:bg-white hover:text-[#ff00b8]"
                        onClick={() => openModal(category)}
                        disabled={!selectedCategories.includes(category.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <label htmlFor="itemsPerPage">Show</label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="form-select bg-white border p-2 rounded"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>

          <div className="flex items-center space-x-2 bg-white p-2 rounded">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-3 bg-white text-gray-400 border border-gray-300 rounded hover:bg-gray-50"
            >
              <FaAngleLeft className="w-4 h-5 mr-2" />
            </button>
            <span className="px-4 py-2 text-black">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-3 bg-white text-gray-400 border border-gray-300 rounded hover:bg-gray-50"
            >
              <FaAngleRight className="w-4 h-5 mr-2" />
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box bg-white">
            <h3 className="font-bold text-lg">Confirm Deletion</h3>
            <p className="py-4">
              Are you sure you want to delete {deleteCategory.categoryName}?
            </p>
            <div className="modal-action">
              <button className="btn btn-error" onClick={confirmDelete}>
                Yes
              </button>
              <button className="btn" onClick={closeModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
